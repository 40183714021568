@import url('https://fonts.googleapis.com/css2?family=Fira+Sans+Condensed&family=MonteCarlo&family=Manrope:wght@400;500;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;
#app {
  height: 100%;
}

*{
  background-repeat: no-repeat;
  box-sizing: border-box;
}
.swiper {
  width: 100%;
  height: 100%;
}
#room-desc{
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.gallery .no-active{
  color: #3E3C38;
}

.gallery .no-active span{
  visibility: hidden
}
.gallery .active-gal{
  font-weight: 600;
}
#btn{
  box-shadow:1px 2px 12px 0px rgba(238, 155, 0, 0.87);
}
.room .active-room{
  color: #EE9B00;
}
.room .no-active-room{
  color: #3E3C38;
}
.service .swiper-slide{
  display: grid;
  border: none;
  margin-top: 50px;
}
.anotherroom .swiper-button-next,.anotherroom  .swiper-button-prev{
  color: #383838;
  font-weight: 800;
}
.ImageGal .swiper-button-next{
  margin-right: 80px;
  background-color: white;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  opacity: 0.6;
}
.ImageGal .swiper-button-prev{
  margin-left: 80px;
  background-color: white;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  opacity: 0.6;
}
input{
  outline: none;
}

#inputdate::-webkit-calendar-picker-indicator{
  width: 32px;
  height: 32px;
  background-image: url(./assets/images/icons/date.svg);
  position: absolute;
  left: 0;
}
input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button{
    display: none;
}
.swiper {
  width: 100%;
  height: 100%;
}


.outswiper .inswiper .swiper .swiper-button-prev{
  position: absolute;
  background-color: rgba(256, 256, 256,0.3) ;
  color: black;
  font-weight: 900;
  border-radius: 50%;
  padding: 25px;
  top: 90%;
  left: 75%;
}
.outswiper .inswiper .swiper .swiper-button-prev::after,
.outswiper .inswiper .swiper .swiper-button-next::after{
  font-size: 20px;
}
.outswiper .swiper .swiper-button-prev::after,
.outswiper .swiper .swiper-button-next::after{
  font-size: 40px;
  color: black;
  font-weight: 900;
}
.outswiper .inswiper .swiper .swiper-button-next{
  position: absolute;
  background-color: rgba(256, 256, 256,0.3);
  color: black;
  font-weight: 900;
  border-radius: 50%;
  padding: 25px;
  top: 90%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.ImageGal .swiper-button-next{
  width: 60px;
  background-color: rgba(256, 256, 256,0.9);
  height: 60px;
}
.ImageGal .swiper-button-prev{
  width: 60px;
  background-color: rgba(256, 256, 256,0.9);
  height: 60px;
}
.ImageGal .swiper-button-next::after{
  font-size: 20px;
  color: black;
}
.ImageGal .swiper-button-prev::after{
  font-size: 20px;
  color: black;
}
.anotherroom-in:hover{
  transition: all 0.4s;
  box-shadow: 0px -2px 10px 0.1px rgb(81, 81, 81);
  /* margin:0px 5px; */
}
.anotherroom-prev,.anotherroom-next{
    color: black;
    font-size: 40px;
    font-weight: bold;
}
.anotherroom-prev{
  margin-left: -40px;
  padding-right: 20px;

}
.anotherroom-next{
  margin-right: -40px;
  padding-left: 20px;
}
.anotherroom .swiper-button-prev::after,.anotherroom .swiper-button-next::after{
  display: none;
}


.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background-color: #EE9B00 !important;
}
.anotherroom{
  padding: 0px 10px;
}
.anotherroom .swiper {
  width: 100%;
  height: 100%;
  padding:0px 0px 13% 0;
}

/* Progress */
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  position: absolute !important;
  left: 0 !important;
  width: 100% !important;
  height:0% !important;
}
.swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  transform-origin: right bottom !important;
}
.swiper-horizontal > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-horizontal,
.swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
  width: 100% !important;
  height: 0% !important;
  top: 99% !important;
  bottom: 0 !important;
}
/* .swiper-vertical > .swiper-pagination-progressbar,
.swiper-pagination-progressbar.swiper-pagination-vertical,
.swiper-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
.swiper-pagination-progressbar.swiper-pagination-horizontal.swiper-pagination-progressbar-opposite {
  width: var(--swiper-pagination-progressbar-size, 4px) !important;
  height: 100% !important;
  left: 0 !important;
  bottom: 0 !important;
} */
@media screen and (max-width:900px) {
  .ImageGal .swiper-button-next{
    margin-right: 20px;
    background-color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    opacity: 0.6;
  }
  .ImageGal .swiper-button-prev{
    margin-left: 20px;
    background-color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    opacity: 0.6;
  }
  .ImageGal .swiper-button-prev::after{
    font-size: 20px;
  }
  .ImageGal .swiper-button-next::after{
    font-size: 20px;
  }
  .anotherroom .swiper-button-next::after,.anotherroom  .swiper-button-prev::after{
    display: none;
  }
  .outswiper .inswiper .swiper .swiper-button-prev{
    position: absolute;
    background-color: rgba(256, 256, 256,0.3) ;
    color: black;
    font-weight: 900;
    border-radius: 50%;
    padding: 25px;
    top: 90%;
    left: 70%;
  }
  
}
@media screen and (max-width:768px) {
  .anotherroom-prev,.anotherroom-next{
    display: none;
  }
  .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    position: absolute !important;
    left: 0 !important;
    width: 100% !important;
    height:100% !important;
  }
  .swiper-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
    transform-origin: right bottom !important;
  }
  .swiper-horizontal > .swiper-pagination-progressbar,
  .swiper-pagination-progressbar.swiper-pagination-horizontal,
  .swiper-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite,
  .swiper-pagination-progressbar.swiper-pagination-vertical.swiper-pagination-progressbar-opposite {
    width: 100% !important;
    height: 100% !important;
    top: 99% !important;
    bottom: 0 !important;
  }
}
@media screen and (max-width:640px) {

  .swiper-pagination-horizontal{
   
    position: absolute !important;
    bottom: 0 !important;
  }
}


